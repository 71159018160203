import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { authGuard } from '@repo/vue/plugins';
import AppLayout from '@/layout/AppLayout.vue';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: '/',
        component: () => import('@/layout/AppMainLayout.vue'),
        beforeEnter: authGuard,
        children: [
          {
            path: '/entities',
            name: 'entities',
            component: () => import('@/views/main/entities/Entities.vue')
          },
          {
            path: '/process',
            component: () => import('@/layout/AppEntityLayout.vue'),
            children: [
              {
                path: '/files',
                name: 'files',
                component: () => import('@/views/main/files/Files.vue')
              },
              {
                path: '/requests',
                name: 'requests',
                component: () => import('@/views/main/requests/Requests.vue')
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/demos',
    name: 'demos',
    children: [
      {
        path: 'data-table-demo',
        name: 'data-table-demo',
        component: () => import('@/views/demos/DataTableDemo.vue')
      }
    ]
  },
  {
    path: '/callback',
    name: 'callback',
    component: () => import('@/views/common/CallBackView.vue')
  },
  {
    path: '/confirm-user-invitation',
    name: 'confirmUserInvitation',
    component: () => import('@/views/common/ConfirmUserInvitation.vue')
  },
  {
    path: '/registration-completed',
    name: 'registrationComplete',
    component: () => import('@/views/common/RegistrationComplete.vue')
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: () => ({ top: 0 }),
  routes
});

export default router;
