import { useScriptTag } from '@vueuse/core';
import { App } from 'vue';
import { ResourceConfig } from '@repo/core/resources';
import { AppConstants } from '@/constants';
import { useAppStore } from '@repo/vue/stores';

export class SetupPlugin {
  install(app: App) {
    const pinia = app.config.globalProperties.$pinia;
    const router = app.config.globalProperties.$router;

    // stores
    const appStore = useAppStore(pinia);

    router.isReady().then(async () => {
      // script tag
      const { load } = useScriptTag(
        `${ResourceConfig.serverBase}enums`,
        () => {
          console.log('enums loaded successfully');
        },
        { manual: true }
      );

      if (appStore.isIFrame) {
        document.documentElement.className = 'app-light';
        document.documentElement.style.fontSize = AppConstants.defaultScale + 'px';
      } else {
        document.documentElement.className = 'app-light';
        document.documentElement.style.fontSize = AppConstants.defaultScale + 'px';
      }
      // load enums
      try {
        await load();
      } catch (e: any) {
        console.error('failed to load enums');
      }
    });
  }
}
