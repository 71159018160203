import { api } from '@repo/core/config';
import { Practice, StoredFolder } from '@/core/models';
import * as _ from 'lodash-es';

export const usePracticeService = () => {
  const getCurrent = async (): Promise<Practice> => {
    try {
      const response = await api.get(`Practice/getCurrent?$expand=*`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getFolders = async (): Promise<StoredFolder[]> => {
    try {
      const response = await api.get(`Practice/getFolders`);
      const data = setFolderData(response.data);
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const deleteFolder = async (folderPath: string) => {
    try {
      folderPath = encodeURIComponent(folderPath);
      const response = await api.delete(`Practice/DeleteFolder?path=${folderPath}`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const renameFolder = async (folder: StoredFolder, newName: string) => {
    try {
      const response = await api.post(
        `Practice/RenameFolder?newName=${encodeURIComponent(_.toString(newName))}`,
        folder
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createFolder = async (path: string, updateEntities: boolean) => {
    try {
      const response = await api.post(
        `Practice/CreateFolder?path=${encodeURIComponent(path)}&updateEntities=${updateEntities}`
      );
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const setFolderData = (folders: StoredFolder[]): StoredFolder[] => {
    folders = _.map(folders, (x) => {
      x.data = { name: x.name };
      return x;
    });
    _.forEach(folders, (folder) => {
      folder.children = setFolderData(folder.children);
    });
    return folders;
  };

  const post = async (data: Practice[], options: any) => {
    if (!options) options = {};
    options.autoSave = options.autoSave != null ? options.autoSave : true;

    try {
      const response = await api.post(`Practice`, data, { headers: options });
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const uploadLogo = async (logo: File) => {
    try {
      const formData = new FormData();
      formData.append('logo', logo);

      return await api.post(`Practice/UploadLogo`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateAuditorFolder = async (folderName: string) => {
    try {
      const response = await api.post(`Practice/UpdateAuditorFolder?folderName=${encodeURIComponent(folderName)}`);
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  return {
    getCurrent,
    post,
    uploadLogo,
    getFolders,
    renameFolder,
    deleteFolder,
    createFolder,
    updateAuditorFolder
  };
};
